import React from 'react';
import './index.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faTwitter} from '@fortawesome/free-brands-svg-icons'
import hero from './assets/hero_nfts.png';
import spacer from './assets/spacer.png';

function App() {
  return (
    <div className="App font-press">
      <section className="flex justify-between bg-greenLight p-3 pr-6 pl-6 items-center">
        <span className="text-blue">MA</span>
        <div className="flex flex-column items-center">
          <div className="mr-6">
            <a className="text-[1.5rem] hover:text-pinkDark text-blue mr-3" href="https://discord.gg/kyqNMtCG"
               target='_blank' rel="noreferrer">
              <FontAwesomeIcon icon={faDiscord}/>
            </a>
            <a className="text-[1.5rem] hover:text-pinkDark text-blue mr-3" href="https://twitter.com/FelineMystical"
               target='_blank' rel="noreferrer">
              <FontAwesomeIcon icon={faTwitter}/>
            </a>
          </div>
          <div className="relative group">
          <button className="bg-red p-2 pr-6 pl-6 drop-shadow-button text-greenLight ">connect</button>
          <span className="absolute bg-green text-xs p-2 left-[-50px] bottom-[-30px] invisible group-hover:visible">coming soon</span>
          </div>
        </div>
      </section>
      <body>
      <section className="container m-auto hero flex flex-col-reverse sm:flex-row items-center py-12 justify-between">
        <div className="p-3 sm:p-0 sm:max-w-[43%] justify-self-end">
          <h1 className="text-pink mb-4 leading-7 text-xl mb-12">welcome to the magic world
            of mystical animals</h1>
          <h3 className="leading-7 mb-12">
            Mystical Felines is a 3333
            collection living in Solana blockchain
            They are waiting for the Mystical Canines
            to complete this magical world
          </h3>
          <div className="mr-6">
            <a className="text-[2rem] hover:text-pinkDark text-blue mr-3" href="https://discord.gg/kyqNMtCG"
               target='_blank' rel="noreferrer">
              <FontAwesomeIcon icon={faDiscord}/>
            </a>
            <a className="text-[2rem] hover:text-pinkDark text-blue mr-3" href="https://twitter.com/FelineMystical"
               target='_blank' rel="noreferrer">
              <FontAwesomeIcon icon={faTwitter}/>
            </a>
          </div>
        </div>
        <div className="min-w-[50%] p-3 sm:p-0 mb-6">
          <img src={hero} alt="mystical animals hero"/>
        </div>
      </section>
      <section className="bg-hero-pattern bg-cover bg-no-repeat py-20 md:mb-12"
               style={{
                 backgroundImage: "url('./assets/pattern.png')"
               }}>
        <div className="p-3 sm:p-0 sm:max-w-[42%] lg:max-w-[40rem] m-auto">
          <h2 className="text-pink mb-4 leading-7 text-xl text-center mb-12">THE MYSTICAL ROAD</h2>
          <h3 className="leading-7">
            <p className="mb-6">Not a long time ago, a 3333 group of felines was abandoned by its creator. But thankfully, there was a builder who rescue them, so..</p>

            <p className="mb-6">...this was just the beginning, right now the road is still unknown to us...</p>

            <p className="mb-6">... but with effort and working together, we can make this kitten shine again!</p>

            <p className="mb-6">Would you be a part of this magical story?</p>
          </h3>
        </div>
      </section>
      <div className="md:mt-[-2px] md:mb-12">
        <img className="m-auto md:rounded-xl" src={spacer} alt="Mystical Animals Spacer "/>
      </div>
      <section className="bg-blueLight py-20">
        <div className="p-3 sm:p-0 sm:max-w-[42%] lg:max-w-[40rem] m-auto">
          <h2 className="text-pink mb-4 leading-7 text-xl text-center mb-12">COLLECTION BENEFITS</h2>
          <h3 className="leading-7">
            <ul className="list-disc">
              <li>Beautiful PFP</li>
              <li>Calendar Dapp, don’t miss your WLs dates never again (Q3) </li>
              <li>Collabs for WLs spots (already ongoing)</li>
              <li>... the builder will hear what its kitten need (Q4 and beyond)</li>
            </ul>
          </h3>
        </div>
      </section>
      <footer className="p-6 bg-red text-greenLight">
        <span>© 2022 mysticalanimals.xyz</span>
      </footer>
      </body>
    </div>
  );
}

export default App;

